// Generated by Framer (8a81902)

import { addFonts, cx, CycleVariantState, Image, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["d0IgxoAr6"];

const variantClassNames = {d0IgxoAr6: "framer-v-ugf3bj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "d0IgxoAr6", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-Yj7CZ", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 357, intrinsicWidth: 387, pixelHeight: 357, pixelWidth: 387, src: "https://framerusercontent.com/images/5nJv5A3lcbcHSWqKTwEXMuqFc.png"}} className={cx("framer-ugf3bj", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"d0IgxoAr6"} ref={ref} style={{borderBottomLeftRadius: 17, borderBottomRightRadius: 17, borderTopLeftRadius: 17, borderTopRightRadius: 17, ...style}}><SVG className={"framer-8sqjj1"} data-framer-name={"Rectangle_4340"} layout={"position"} layoutDependency={layoutDependency} layoutId={"NMFhZMpTR"} opacity={1} radius={11} style={{borderBottomLeftRadius: 11, borderBottomRightRadius: 11, borderTopLeftRadius: 11, borderTopRightRadius: 11}} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 99 98\"><g transform=\"translate(-9 -10.239)\" id=\"ss2718795482_1\"><path d=\"M 22.075 118 C 9.884 118 -0 118 -0 118 L -0 0 C -0 0 9.884 0 22.075 0 L 94.925 0 C 107.116 0 117 0 117 0 L 117 118 C 117 118 107.116 118 94.925 118 Z\" fill=\"rgb(255,255,255)\"></path></g></svg>"} svgContentId={2718795482} withExternalLayout/></Image></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-Yj7CZ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Yj7CZ .framer-yl03un { display: block; }", ".framer-Yj7CZ .framer-ugf3bj { height: 98px; overflow: hidden; position: relative; width: 99px; will-change: var(--framer-will-change-override, transform); }", ".framer-Yj7CZ .framer-8sqjj1 { flex: none; height: 98px; left: calc(49.494949494949516% - 99px / 2); position: absolute; top: calc(50.00000000000002% - 98px / 2); width: 99px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 98
 * @framerIntrinsicWidth 99
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FramerzULUgaCJO: React.ComponentType<Props> = withCSS(Component, css, "framer-Yj7CZ") as typeof Component;
export default FramerzULUgaCJO;

FramerzULUgaCJO.displayName = "Rectangle_4340";

FramerzULUgaCJO.defaultProps = {height: 98, width: 99};

addFonts(FramerzULUgaCJO, [])